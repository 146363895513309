<template lang='pug'>
.container(v-loading.fullscreen.lock='loading' :element-loading-text='"避免互相干擾，請稍等 " + waiting30Counter + " 秒。"')
  .check(v-show='checkVisible')
    //- h2.title Re-Generative::update()
    h2.title 手指鼓大賽
    h2.title Finger Drumming Play
    h4.title-sub 手動機制
    
    el-card(shadow='hover')
      el-button.button(@click='reset') Reset

</template>

<script>
// @ is an alias to /src
// import * as Tone from 'tone'

export default {
  name: 'Home',
  components: {
    // Tone,
  },
  data() {
    return {
      socket: '',
      loading: false,
      checkVisible: true,
      infoVisible: true,
      timeout: null,
      currentTime: 0,
      characterVisible: true,
      clickVisible: false,
    }
  },
  mounted() {

    // eslint-disable-next-line no-undef
    // this.socket = io('https://localhost:5050', {
      // eslint-disable-next-line no-undef
      this.socket = io('https://finger-drumming-play.clab.org.tw:7070', {
      withCredentials: true,
      extraHeaders: {
        'finger-drumming-play': 'abcd',  
      },
      query: {
        'client': 'mobile',
      },
    });

    this.socket.on('connect', () => {
      console.log('socket.io is connected');
      this.socket.emit('onReady');
    });

    this.socket.on('ready', (online) => {
      console.log('Got READY.', online);
      this.socketData.online = online;
      // this.startCheck = true;
    });

    // this.music = new Audio();
    // this.music.pause();
    // this.socket.emit('rockBlink');
    // this.noSleep();
  },
  destroyed() {
    this.socket.disconnect();
  },
  methods: {
    reset() {
      this.socket.emit('reset');
    },
  },
}
</script>

<style lang='scss' scoped>
.container {
  // background-image: url('/img/bg.jpeg');
  background-color: rgb(29, 29, 29);
  height: auto;
  width: 100vw;
  padding-bottom: 100px;

  .title {
    color: aliceblue;
  }

  .check {
    padding-top: 10vh;
    margin-left: auto;
    margin-right: auto;
    width: 300px;
  }

  .el-card {
    margin-top: 20px;
  }

  .last-card {
    margin-bottom: 50px;
  }

  .modeSelect {
    text-align: left;

    .modeItem {
      padding: 10px;
    }
  }

  .black {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgb(18, 18, 204);
    z-index: 9999;
    pointer-events: none;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity .05s;
  }

  .fade-enter,
  .fade-leave-to

  /* .fade-leave-active below version 2.1.8 */
    {
    opacity: 0;
  }
}
</style>