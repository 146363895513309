import { render, staticRenderFns } from "./Warrick.vue?vue&type=template&id=85a21932&scoped=true&lang=pug&"
import script from "./Warrick.vue?vue&type=script&lang=js&"
export * from "./Warrick.vue?vue&type=script&lang=js&"
import style0 from "./Warrick.vue?vue&type=style&index=0&id=85a21932&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "85a21932",
  null
  
)

export default component.exports